@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@700&display=swap');
.accent-color{
  color: #03F0D4;
}

body{
  background-color: rgb(0,0,0);
  font-family: 'Raleway', sans-serif;
  color:white
}

table{
  width: 100%;
}

td{
  padding: 15px 0 15px 0;
  text-align: center;
}

thead{
  border-style: solid;
  border-color: transparent transparent #03F0D4 transparent;
  border-width: 1px;
}

th{
  padding: 10px 0 10px 0;
}

tbody>tr{
  transition-duration: 250ms;
}

tbody>tr:hover{
  background-color: rgba(255, 255, 255, .1);
}

.box-middle{
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -60%);
}

.responsive-size{
  width: 50%;
  max-width: 600px;
  min-width: 350px;
}

.action-btn{
  position: relative;
  display: flex;
  align-items: center;
  background: #f3ff26;
  border-radius: 99999px;
  color:black;
  font-weight: 900;
  text-transform: uppercase;
  padding: 5px 20px 5px 20px;
  outline-offset: 0px;
  outline: solid white 0px;
  transition-timing-function: linear;
  transition-duration: 100ms;
  width: fit-content;
  z-index: 0;
}

.fancy-hover::after{
  z-index: -1;
  content: "";
  opacity: 0%;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 18px 10px 0;
  border-color: transparent #03F0D4 transparent transparent;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.fancy-hover:hover::after{
  opacity: 100%;
  right: -40px;
  border-width: 15px 24px 15px 0;
}

.fancy-hover::before{
  z-index: -1;
  content: "";
  opacity: 0%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 18px;
  border-color: transparent  transparent  transparent #03F0D4;
  transition-timing-function: ease-in-out;
  transition-duration: 250ms;
}

.fancy-hover:hover::before{
  opacity: 100%;
  left: -40px;
  border-width: 15px 0 15px 24px;

}

.action-btn:hover{
  outline-offset: 2px;
  outline: solid white 3px;
}

.action-btn:active{
  background-color: transparent;
  color: #f3ff26;
}

.action-btn:disabled{
  opacity: 75%;
}

input{
  outline: none;
}

.input-holder{
  position: relative;
  border-bottom: solid #03F0D4 2px;
}

.input-holder>input{
  padding: 10px 15px 10px 15px;
  background-color: rgba(255, 255, 255, .1);
  width: 100%;
}

.input-holder>label{
  font-weight: 600;
  font-size: small;
  position: absolute;
  left: 10px;
  top: 0;
  transform: translate(0, -50%);
  transition-duration: 200ms;
}

.input-holder>button{
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(50%, -50%);
  transition-duration: 250ms;
  opacity: 100%;
  font-size: 20px;
}

.input-holder>input:active,
.input-holder>input:focus{
  background-color: rgba(255, 255, 255, .1);
}

.input-holder>input:placeholder-shown + label{
  font-size: medium;
  top: 50%;
}


.input-holder>input:active  + label + button.see,
.input-holder>input:focus  + label + button.see{
  font-size: 20px;
  display: block;
  opacity: 100%;
}

.input-holder>input:placeholder-shown + label + button{
  opacity: 0%;
  font-size: 0;
}

.input-holder>input:active + label,
.input-holder>input:focus + label
{
  font-size: small;
  top: 0px;
}

input:read-only{
  color: rgba(255, 255, 255, .75);
}

input[type='file']{
  display: none;
}

.code-holder{
  display: flex;
  gap: 20px;
  font-family: 'Figtree', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.code-holder>input{
 background-color: rgba(255, 255, 255, .1);
 border-bottom: solid #03F0D4 2px;
 width: 40px;
 text-align: center;
 font-size: 30px;
 font-weight: 600;
}

.big-btn{
  text-align: center;
  border: #03F0D4 solid 1px;
  border-color: #03F0D4 transparent transparent transparent;
  padding: 20px 10px 20px 10px;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  outline: solid 0 transparent ;
  transition-timing-function: linear;
  transition-duration: 100ms;
  margin: 0 auto 0 auto;
  font-size: 20px;
}

.big-btn:first-child{
  border-style: none;
}

.big-btn:hover{
  background-color: rgba(255, 255, 255, .1);
}

.big-btn:only-child{
  border: #03F0D4 solid 1px;
  border-color: #03F0D4 transparent #03F0D4 transparent;
}

.nav-holder{
  display: flex;
  width: fit-content;
}

.nav-btn:first-child{
  border: 1px none #03F0D4;
}


.nav-btn{
  border: 1px solid #03F0D4;
  border-color:transparent transparent transparent #03F0D4;
  padding: 10px 20px 10px 20px;
  transition-duration: 200ms;
  display: flex;
  align-items: center;
}

.nav-btn:hover{
  background-color: rgba(255, 255, 255, .1);
}

.nav-btn.active{
  font-weight: 700;
  color: black;
  background-color: #03F0D4;
}

.order-btn-holder>button{
  color: #03F0D4;
  animation-duration: 200ms;
}

.order-btn-holder>button:hover{
  opacity: 80%;
}
.order-btn-holder>button:disabled{
  color: white;
  opacity: 60%;
}