.nav-logo{
  position: relative;
  width: 100px;
}

.nav-logo.edit>span{
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-logo.edit>span:hover{
  opacity: 1;
  background-color: rgba(0, 0, 0, .5);
}
