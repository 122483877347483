@keyframes fade-in {
  from{
    opacity: 0%;
  }
  to{
    opacity: 100%;
  }
}

@keyframes from-left {
  from{
    transform: translate(-75px, 0);
  }
  to{
    transform: translate(0,0);
  }
}

@keyframes from-bottom {
  from{
    transform: translate(0, 100px);
  }
  to{
    transform: translate(0,0);
  }
}

#element-one{
  position: fixed;
  top: 30px;
  left: 50px;
  animation: 1000ms ease 0s fade-in, 1000ms ease-in-out 0s from-left;
}

#element-one>svg{
  width: clamp(100px, 20vw, 300px);
}


#element-two{
  position: fixed;
  bottom: 30px;
  right: 50px;
  animation: 1000ms ease-in-out 0s fade-in, 1000ms ease-in-out 0s from-bottom;
}

#element-two>svg{
  width: clamp(50px, 10vw, 150px);
}

