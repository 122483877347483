.backdrop{
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
}

@keyframes fade-in {
  from{
    transform: translate(-50%, calc(-50% + 25px));
    opacity: 0%;
  }
  to{
    transform: translate(-50%, -50%);
    opacity: 100%;
  }
}

.modal{
  animation: 500ms ease-in-out 0s fade-in;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 0;
  background-color: rgba(0, 0, 0, 1);
  padding-bottom: 20px;
  max-height: 75%;
  
  border: 2px solid rgba(255, 255, 255, .1);

  overflow-y: auto;
  overflow-x: hidden;
  z-index: 11;
}

.modal-children{
  margin-top: 10px;
  padding: 0 10% 0 10%;
}

.modal.md{
  max-width: 600px;
  min-width: 350px;
  width: 50%;
}

.modal.lg{
  max-width: 800px;
  min-width: 350px;
  width: 80%;
}

.are-you-sure{
  animation: 500ms ease-in-out 0s fade-in;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  max-height: 75%;
  z-index: 11;

}

@keyframes toast-in-out {
  0%{opacity: 0%;}
  10%{opacity: 100%;}
  90%{opacity: 100%;}
  100%{opacity: 0%;}
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #03F0D4;
  height: 100px;
  max-width: 400px;
  width: 40%;
  min-width: 200px;
  padding: 20px 30px 20px 30px;
  opacity: 0%;
  animation: 3000ms linear 0s toast-in-out;
  z-index: 11;

}

@keyframes bar {
  from{width: 100%;}
  to{width: 0%;}
}

.toast::after{
  background-color: rgba(0, 0, 0, 0.5);
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 10px;
  animation: 3000ms linear 0s bar;
}