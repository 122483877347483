.error-holder{
  display: block;
  position: absolute;
  margin-inline: auto;
  width: 40%;
  min-width: 300px;
  right: 50%;
  top: 50%;
  transform: translate(50%, 50%);

}



.error-triangles{
  position: relative;
  transform: translateY(-150px);
}

.error-triangles>svg{
  position: fixed;
  aspect-ratio: 1;
  width: 200px;
  right: 50%;
  top: 50%;
  transform: translate(50%, 50%);

}

@keyframes fade-in {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

@keyframes triangle-fade-in {
  0%{
    opacity: 0;
  }
  75%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@keyframes error-one {
  from{
    transform: translate(50%, 50%) rotate(0) ;
  }
  to{
    transform: translate(calc(50% - 250px), calc(50% + 100px)) rotate(500deg) ;
  }
}

@keyframes error-one {
  from{
    transform: translate(50%, 50%) rotate(0) ;
  }
  to{
    transform: translate(calc(50% + 250px), calc(50% + 100px)) rotate(-250deg) ;
  }
}

@keyframes error-two {
  from{
    transform: translate(50%, 50%) rotate(0) ;
  }
  to{
    transform: translate(calc(50% - 250px), calc(50% + 100px)) rotate(200deg) ;
  }
}

@keyframes error-three {
  from{
    transform: translate(50%, 50%) rotate(0) ;
  }
  to{
    transform: translate(calc(50% - 30px), calc(50% - 300px)) rotate(-140deg) ;
  }
}


.error-triangles>svg:nth-child(1){
  transform-origin: center;
  animation: triangle-fade-in 1500ms ease-in 0s, error-one 2000ms ease-out 0s !important;
  opacity: 0;
}
.error-triangles>svg:nth-child(2){
  animation: triangle-fade-in 1500ms ease-in 0s, error-two 2000ms ease-out 0s !important;
  opacity: 0;
}
.error-triangles>svg:nth-child(3){
  animation: triangle-fade-in 1500ms ease-in 0s, error-three 2000ms ease-out 0s !important;
  opacity: 0;
}

.error-holder>h1{
  text-align: center;
  font-size: 30px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  animation: fade-in 1500ms ease-in 0s;

} 