.loading-holder{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-75%, -50%);

  min-width: 150px;
  width: 25%;
  max-width: 250px;
  height: 200px;
}

.loading-holder>svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes one {
  0%{
    opacity: 0%;
    transform: translate(calc(-50% - 100%), -50%);
  }

  100%{
    opacity: 100%;
    transform: translate( calc(100% - 100%), -50%);
  }
}

.loading-holder>svg:nth-child(1){
  opacity: 20%;
  transform: translate(calc(-50% - 100%), -50%);
  animation: 1500ms cubic-bezier(.31,.69,.55,.89) 0ms one infinite;
}

@keyframes two {
  0%{
    opacity: 0%;
    transform: translate(calc(-50% - 25%), -50%);
  }

  100%{
    opacity: 0%;
    transform: translate( calc(100% - 25%), -50%);
  }
}

.loading-holder>svg:nth-child(2){
  opacity: 0%;
  transform: translate(calc(-50% - 25%), -50%);
  animation: 1500ms cubic-bezier(.31,.69,.55,.89) 750ms one infinite;
}

@keyframes three {
  0%{
    opacity: 100%;
    transform: translate( calc(100% - 100%), -50%);
  }

  100%{
    opacity: 0%;
    transform: translate( calc(100% + 25%), -50%);
  }
}

.loading-holder>svg:nth-child(3){
  opacity: 0%;
  transform: translate( calc(100% - 100%), -50%);
  animation: 1500ms cubic-bezier(.31,.69,.55,.89) 1500ms three infinite;
}

@keyframes two {
  0%{
    opacity: 0%;
    transform: translate(calc(-50% - 25%), -50%);
  }

  100%{
    opacity: 0%;
    transform: translate( calc(100% - 25%), -50%);
  }
}

.loading-holder>svg:nth-child(4){
  opacity: 0;
  transform: translate(calc(-50% - 25%), -50%);
  animation: 1500ms cubic-bezier(.31,.69,.55,.89) 2250ms three infinite;
}