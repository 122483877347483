.log-out-btn{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}

.log-out-btn span{
  opacity: 0;
  transition-duration: 250ms;
}

.log-out-btn button:hover + span{
  opacity: 100%;
}